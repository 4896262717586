import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import notFoundIllustration from '../images/not-found.svg'

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="prose mx-auto my-10 px-4 md:px-0 flex flex-col items-center">
        <img
          alt="Not found svg"
          className="block mx-auto w-3/4 md:w-1/2"
          src={notFoundIllustration}
        />
        <h2>The page you are looking for does not exist.</h2>
        <h3>
          Click{' '}
          <Link to="/" aria-label="Home link">
            here
          </Link>{' '}
          to return to the home page.
        </h3>
      </div>
    </Layout>
  )
}

export default NotFoundPage
